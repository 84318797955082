figure .image {
    width: 340%;
    height: auto;
}

#fig {
    position: absolute;
    text-align: center;
}

figure {
    text-align: center;
    font-size: 12px;
    font-family: "Consolas", "Menlo", "Lucida Console", "Courier New", monospace;
    display: inline-block;
    max-width: 30%; /* demo; set some amount (px or %) if you can */
    margin: 10px auto; /* not needed unless you want centered */
}

.leaflet-container {
    height: 500px;
    width: 100%;
}

#mapId {
    height: 500px;
    width: 100%;
}

.leaflet-container .markerSpan {
    font-family: Helvetica, "Nimbus Sans L", "Liberation Sans", Arial, sans-serif;
    font-size: small;
}