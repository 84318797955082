#imageID {
    width: 320px;
    padding: 10px;
    height: 240px;
}

#caption-0 {
    font-style: italic;
    padding: 5px;
    text-align: center;
}

#figureID {
    display: block;
    text-align: center;
}

figure.image {
    position: relative;
}

figure.image > figcaption {
    position: absolute;
    text-align: center;
}